button,
button:focus,
button:hover {
  overflow: hidden;
  cursor: pointer;
}

/* Public */
.color-lendis-main {
  color: #00af54;
}

button.link {
  color: #00af54;
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: #007136;
}

.ant-page-header {
  padding-left: 0;
}

.ant-layout-header {
  background: #ffffff;
  padding: 0;
  height: auto;
}

.site-layout-content {
  padding: 16px 16px 80px;
  background: #fafafa;
}

.ant-breadcrumb {
  margin: 16px;
}

.ant-btn {
  color: #00af54;
  background: #fff;
  border-color: #00af54;
}
.ant-btn.ant-btn-link {
  border-color: transparent;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #007136;
  background: #fff;
  border-color: #007136;
}

.ant-btn.ant-btn-link:hover,
.ant-btn.ant-btn-link:focus {
  border-color: transparent;
}

.ant-btn-primary {
  color: #fff;
  background: #00af54;
  border-color: #00af54;
}

.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #007136;
}

.ant-btn-default,
.ant-btn-text {
  color: #00af54;
}

.ant-btn.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}

.ant-btn-dangerous.ant-btn-primary {
  background: #ff4d4f;
  color: #fff;
}

.ant-btn-dangerous.ant-btn-text {
  border-color: #ff4d4f;
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  border-color: #ff4d4f;
}

.warning-button.ant-btn-primary {
  border: none;
  background-color: #c27803;
}

.warning-button.ant-btn-default,
.warning-button.ant-btn-text {
  border-color: #c27803;
  color: #c27803;
}

.info-button.ant-btn-primary {
  border: none;
  background-color: #41b5f6;
}

.info-button.ant-btn-default,
.info-button.ant-btn-text {
  border-color: #41b5f6;
  color: #41b5f6;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

.text-red {
  color: #ff4d4f;
}

.text-gray {
  color: #6b7280;
}

.text-green {
  color: #00af54;
}

.delivery-selection-box {
  text-align: center;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  border-width: 1px;
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

.delivery-selection-input {
  color: #6b7280;
  bottom: 0;
  position: absolute;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
}

/* Table */
.table-striped-rows {
  margin: 1rem 0;
  border-top: 1px solid #d0d0d0;
  color: #6b7280;
}

.anticon {
  vertical-align: 0;
}

.table-striped-rows thead tr th {
  background-color: #f9fafb;
}

.table-striped-rows tr:nth-child(2n),
.table-striped-rows tr:nth-child(2n) .ant-table-cell-fix-left,
.table-striped-rows tr:nth-child(2n) .ant-table-cell-fix-right {
  background-color: #e5e5e5;
}

.ant-table-tbody tr:nth-child(odd) .ant-table-column-sort {
  background-color: white;
}

.ant-table-tbody tr:nth-child(even) .ant-table-column-sort {
  background-color: #e5e5e5;
}

.table-striped-rows tr:hover,
.table-striped-rows .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #def7ec;
}

.table-striped-rows .ant-layout {
  background: transparent;
}

.table-striped-rows .ant-btn-icon-only {
  font-size: 12px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5bac5e;
  border-color: #5bac5e;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #5bac5e;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #5bac5e;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #5bac5e;
}

.ant-checkbox-checked::after {
  border: 1px solid #5bac5e;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #eafeea;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #d7ffd9;
}

.no-border {
  border: none;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.cartTitles {
  width: calc(100% / 4);
  background: #eaeaea;
  text-align: center;
  padding: 2rem 3rem;
  margin: 1rem 0;
}

.instant-search-reload-button {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 2.5rem;
  cursor: pointer;
}

.instant-search-icon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
}

.border-right-map-default {
  border-right: 6px solid #4a5568;
}

.border-right-map-selected {
  border-right: 6px solid #00af54;
}

.indeterminate-top {
  width: 100%;
  overflow: hidden;
  margin: 1rem auto;
  height: 0.5rem;
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity));
}

.indeterminate {
  height: 100%;
  width: 100%;
  position: relative;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #00af54;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #0d7f43;
  animation: indeterminate_second 1.5s infinite ease-in;
}

.w-128 {
  width: 32rem;
}

.fixed-width-description th.ant-descriptions-item-label {
  width: 15%;
}

.wizardContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 100%;
}

.top-search-suggestion {
  width: 36rem;
  top: 2.75rem;
  right: 0;
  position: absolute;
  padding: 0;
  overflow: scroll;
  max-height: 24rem;
  height: auto;
  border-width: 1px;
  border-radius: 0.5rem;
  --bg-opacity: 1;
  background-color: #fff;
}

.top-search-suggestion-object-response-hits {
  border-radius: 0.5rem;
  vertical-align: middle;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  min-width: 100%;
  display: inline-block;
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #e5e7eb;
}

.top-search-suggestion-table-track-wider {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  --text-opacity: 1;
  color: #161e2e;
  text-align: left;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  line-height: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #e5e7eb;
  --bg-opacity: 1;
  background-color: #a0a0a0;
}

.break-all {
  word-break: break-all;
}

.algolia-search-button {
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  opacity: 0.5;
  height: 100%;
  cursor: default;
}

.algolia-search-button-label {
  letter-spacing: 0.025em;
  --text-opacity: 1;
  color: #374151;
  font-size: 0.75rem;
  display: block;
}

.algolia-search-button-ul {
  width: 100%;
  position: relative;
  padding: 0;
  --bg-opacity: 1;
  background-color: #ffffff;
  list-style-type: none;
}

.algolia-search-button-li {
  padding: 0.5rem 0.75rem;
}

.algolia-search-button-li:hover,
.algolia-search-button-li:focus {
  cursor: pointer;
  --bg-opacity: 1;
  background-color: #c3ddfd;
}

.error-text {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.layout-forms {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.top-search-suggestion-object-hit {
  white-space: nowrap;
  --text-opacity: 1;
  color: #6b7280;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #e5e7eb;
}

.hidden {
  display: none;
}

.modal {
  z-index: 20;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal-box {
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  padding: 2.5rem;
  --bg-opacity: 1;
  background-color: #fff;
  width: 44rem;
}

.modal-box-content {
  padding-left: 0.75rem;
  margin-left: auto;
}

.modal-box-content-child {
  right: 0;
  top: 0;
  position: absolute;
  margin: 0.75rem 0.75rem -0.375rem -0.375rem;
}

.modal-close-button {
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform;
  --text-opacity: 1;
  color: #6b7280;
  padding: 0.375rem;
  display: inline-flex;
  border-radius: 0.375rem;
  cursor: pointer;
}

.modal-close-button:hover {
  --bg-opacity: 1;
  background-color: #f4f5f7;
}

.modal-close-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --bg-opacity: 1;
  background-color: #f4f5f7;
}

.modal-xl {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
  padding: 15px;
}

.rounded-cornerns-modal .ant-modal-content {
  border-radius: 8px;
}

.view-pagination {
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-top-width: 1px;
  --border-opacity: 1;
  border-color: #e5e7eb;
}

@media (max-width: 640px) {
  .view-pagination {
    padding-left: 0;
    padding-right: 0;
  }
}

.view-pagination-data {
  width: 0;
  flex: 1 1;
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.view-pagination-style {
  padding-top: 1rem;
  margin-top: -1px;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  align-items: center;
  display: inline-flex;
  border-top-width: 2px;
  border-top: solid;
  border-color: transparent;
}

.view-pagination-transition {
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform;
}

.notification-success {
  background-color: #31c48d;
  color: #fff;
}

.notification-warning {
  background-color: #e3a008;
  color: #fff;
}

.notification-error {
  background-color: #f98080;
  color: #fff;
}

.view-pagination-button:hover {
  --text-opacity: 1;
  color: #374151;
  border-top: solid;
  --border-opacity: 1;
  border-color: #d2d6dc;
}

.view-pagination-button:focus {
  --text-opacity: 1;
  color: #374151;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-top: solid;
  --border-opacity: 1;
  border-color: #9fa6b2;
}

.view-pagination-number {
  padding-left: 1rem;
  padding-right: 1rem;
}

.view-pagination-number:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.view-pagination-number-active {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-top: solid;
  --text-opacity: 1;
  color: #057a55;
}

.view-pagination-number-active:focus {
  --text-opacity: 1;
  color: #03543f;
  border-top: solid;
  --border-opacity: 1;
  border-color: #046c4e;
}

.view-pagination-number-passive {
  border-color: transparent;
}

.view-pagination-number-passive:hover {
  --text-opacity: 1;
  color: #374151;
  border-top: solid;
  --border-opacity: 1;
  border-color: #d2d6dc;
}

.view-pagination-number-passive:focus {
  --text-opacity: 1;
  color: #374151;
  border-top: solid;
  --border-opacity: 1;
  border-color: #9fa6b2;
}

.view-pagination-number-show {
  display: flex;
}

@media (max-width: 1024px) {
  .view-pagination-number-show {
    display: none;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

.bg-grey {
  background-color: #fafafa;
}

.table-data {
  margin-top: 12px;
}

.table-data tr td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ant-steps-item-process .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5bac5e;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #5bac5e;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #5bac5e;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #5bac5e;
}

.flex-payment-overview .ant-table-thead > tr > th {
  font-weight: bold;
}

.show-scrollbar-on-mac {
  padding-right: 7px;
}
.show-scrollbar-on-mac::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.show-scrollbar-on-mac::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.table-collapse-groups .ant-table-tbody .ant-table-selection-column {
  display: none;
}

.table-collapse-groups-show-checkboxes .ant-table-selection-column {
  display: table-cell !important;
  padding: 12px 18px 12px 24px !important;
}

.collapse-no-inner-paddings .ant-collapse-content-box {
  padding: 0;
}

.collapse-no-inner-paddings .ant-collapse-content {
  border: none;
}

.collapse-no-inner-paddings .ant-collapse-header {
  padding: 8px 40px 8px 0 !important;
}

.collapse-no-inner-paddings .ant-table-cell {
  padding: 6px;
  min-height: 37px;
  border: none;
}

.collapse-no-inner-paddings .ant-table-row {
  margin-top: 8px;
}

.collapse-no-inner-paddings .ant-table-cell:last-child {
  padding-right: 60px;
}

.table-no-background-on-selected .ant-table-tbody > tr.ant-table-row-selected > td,
.table-no-background-on-selected .ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.table-collapse-groups.header-labels-right-except-first
  .ant-table-thead
  .ant-table-cell:first-child {
  display: block;
  width: 64px;
}

.table-collapse-groups .ant-table-thead > tr > th {
  padding: 8px;
  border: none;
}

.table-collapse-groups.header-labels-right-except-first .ant-col {
  display: flex;
  align-items: center;
  padding: 16px;
}
.table-collapse-groups.header-labels-right-except-first .ant-table-cell:nth-child(2) {
  width: 56%;
}
.table-collapse-groups.header-labels-right-except-first .ant-table-cell:nth-child(3) {
  width: 23%;
}
.table-collapse-groups.header-labels-right-except-first .ant-table-cell:nth-child(4) {
  width: 16%;
  padding-right: 40px;
}

.table-collapse-groups
  .ant-table-tbody
  .order-detail-bestellpositionen-custom-margins
  .ant-table-cell
  .ant-form {
  margin: -14px -16px 0px -16px;
}

.order-detail-bestellpositionen-custom-margins > .ant-table-cell {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.table-collapse-groups
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  height: 87%;
  align-items: center;
  display: flex;
  padding: 0;
  right: 24px;
}

.table-collapse-groups .ant-collapse-content-box {
  padding-top: 0 !important;
}

.ant-collapse-content {
  border-top: none;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.up-to-down-arrow .ant-collapse-header[aria-expanded='false'] .ant-collapse-arrow svg {
  transform: rotate(90deg);
}
.up-to-down-arrow .ant-collapse-header[aria-expanded='true'] .ant-collapse-arrow svg {
  transform: rotate(-90deg) !important;
}

.up-to-down-arrow.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 16px;
}

.ant-table-tbody > tr.data-row-with-warning > td {
  border-bottom: none;
}

.no-hover-effect .ant-table-tbody > tr:hover > td {
  background-color: transparent;
}

.table-header-background-dark-blue th {
  background-color: #edf2f7 !important;
  font-weight: 700 !important;
}

.table-header-background-light-blue th {
  background-color: rgb(247, 250, 252) !important;
  font-weight: 500 !important;
}

.return-items-date-picker {
  position: relative;
  right: 60px;
}

.ant-input-number.always-show-controls .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  fill: #00af54;
}

.ant-input-number.borderless-addon {
  border-right: none;
}
.ant-input-number.borderless-addon + .ant-input-number-group-addon {
  background-color: #ffffff !important;
  color: #8c8c8c;
  font-size: 12px;
}
.ant-input-number.borderless-addon.ant-input-number-focused {
  border-right-width: 0px !important;
  clip-path: inset(-5px 0px -5px -5px);
}
.ant-input-number.borderless-addon.ant-input-number-focused + .ant-input-number-group-addon {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px !important;
  outline: 0;
  clip-path: inset(-5px -5px -5px 0px);
}
.ant-input-number.borderless-addon:hover + .ant-input-number-group-addon {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.ant-table-wrapper.no-header-label-separator .ant-table-cell::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #1a202c;
}

.ant-switch-checked {
  background: #00af54;
}

.akeneo-message-collapse .ant-collapse-item {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.akeneo-message-collapse .ant-collapse-header {
  padding: 5px 10px !important;
}

.akeneo-message-collapse .ant-collapse-content-box {
  padding: 10px 10px 40px !important;
}

.akeneo-message-collapse .alertConfirmBtn {
  top: 250% !important;
  color: white;
  background: #00af54;
}

.akeneo-message-collapse .akeneo-panel-header .ant-collapse-header {
  font-weight: bold;
}

.iframe-paper {
  border: none;
  background: white;
  width: 100%;
  height: 100vh;
  box-shadow: 1px 1px 4px #ddd;
}

.cancellationProposal-inventories .ant-collapse-item-disabled > * {
  cursor: default !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.inventory-edit .ant-form-item-label > label {
  font-weight: bold;
}

.flex-table-sum-row {
  background-color: #f9f9f9;
  font-weight: bold;
}

@media (max-width: 1280px) {
  .table-with-pagination {
    min-width: 1280px;
  }
}
